@font-face {
  font-family: 'Avenir LT Std';
  src: url('./fonts/AvenirLTStd-Black.woff2') format('woff2'),
      url('./fonts/AvenirLTStd-Black.woff') format('woff'),
      url('./fonts/AvenirLTStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('./fonts/AvenirLTStd-Roman.woff2') format('woff2'),
      url('./fonts/AvenirLTStd-Roman.woff') format('woff'),
      url('./fonts/AvenirLTStd-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('./fonts/AvenirLTStd-Book.woff2') format('woff2'),
      url('./fonts/AvenirLTStd-Book.woff') format('woff'),
      url('./fonts/AvenirLTStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  outline: none !important;
}

body {
  font-family: 'Avenir LT Std', sans-serif;
}

.homepage {
  height: 100vh;
}

.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}
video {
  min-width: 100%;
  min-height: 100vh;
  z-index: 100;
}
.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  z-index: 2;
  opacity: 0.4;
}
.elevate-content {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  max-width: 100%;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* background: #00000073; */
  padding: 25px 25px;
}

.logo-cont {
  display: flex;
  align-items: center;
}

.logo {
  width: 270px;
  max-width: 100%;
}

.enter-cont button {
  border: 0px;
  background: #fff;
  color: #000E;
  padding: 8px 45px;
  border-radius: 4px;
  letter-spacing: 2px;
  font-size: 18px;
  box-shadow: 0px 0px 10px #00000047;
  transition: 0.2s;
}
.enter-cont button:hover {
  background: #c7c7c7;
  color: #000;
}
.skylight-close-button {
  font-size: 45px !important;
  top: -8px !important;
  color: #000 !important;
  text-decoration: none !important;
}

.form-cont {
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 25px;
}

.skylight-dialog {    
  margin-top: -350px !important;
  background-color: rgb(255 255 255 / 95%) !important;
}

.submit-btn {
  border: 0px;
  background: rgb(0, 0, 0);
  color: #fff;
  padding: 8px 45px;
  border-radius: 4px;
  letter-spacing: 2px;
  font-size: 18px;
  box-shadow: 0px 0px 10px #00000047;
  transition: 0.2s;
}

.submittedDialog .skylight-dialog {
  display: flex;
  align-items: center;
  min-height: 250px !important;
  width: 34% !important;
  left: 55% !important;
  margin-top: -150px !important;
}

.submit-message {
  padding: 0 60px;
  text-align: center;
}

.submit-message h4 {
  font-weight: 400;
  line-height: 35px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 600px) {
  .form-cont {
    max-height: 500px;
    overflow-x: scroll;
  }
  .join-text{
    font-size: 20px !important;
    margin-top: 30px;
  }
  .form-label {
    font-size: 14px;
  }
  .elevate-content {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80%;
  }
  .logo-cont {
    justify-content: center;
    margin-bottom: 40px;
  }
  .enter-cont {
    justify-content: center;
    display: flex;
  }
  .skylight-dialog {
    margin-top: 0 !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    margin-left: 0 !important;
  }
  .submittedDialog .skylight-dialog {
    margin-top: 60% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    margin-left: 0 !important;
  }
}